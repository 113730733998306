import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";


const fileTypes = ["JPG", "PNG", "GIF"];
const Publicitat = () => {
    const [file, setFile] = useState(null);
    const [publicitats, setPublicitats] = useState([]);

    const handleChange = (file) => {
        setFile(file);

    };




    const updatePubli = () => {

        if (!file) {
            alert("No has seleccionat cap imatge");
            return;
        }


        const formData = new FormData();
        formData.append('file', file); // 'file' debe coincidir con el nombre del campo esperado en el servidor
        formData.append('text', 'text');

        fetch(process.env.REACT_APP_API_BASE_URL + '/publicitat/guardarpublicitat', {
            method: 'POST',
            headers: {
                'Token': localStorage.getItem('token'),
            },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                getPublicitat();
                if (data.error) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                alert("modifica la taula, per afagir el link i la posició");
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
            });
    };


    const getPublicitat = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/publicitat/getpublicitat', {
            method: 'GET',

        })
            .then(response => response.json())
            .then((data) => {
                if (data.error) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }

                setPublicitats(data.message);
            })
            .catch(error => {
                console.error('Error updating news:', error);
            }
            );
    };


    const guardarPubli = () => {
        //valida que dentro del array no tenga el publicitat orden repetido 
        let ordenRepetido = false;
        let ordenes = [];
        publicitats.forEach((publicitat) => {
            if (ordenes.includes(publicitat.orden)) {
                ordenRepetido = true;
            }
            ordenes.push(publicitat.orden);
        });
        if (ordenRepetido) {
            alert("No pot haver-hi dos publicitats amb el mateix ordre");
            return;
        }

        fetch(process.env.REACT_APP_API_BASE_URL + '/publicitat/guardarpublicitatBBDD', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('token'),
            },
            body: JSON.stringify(publicitats),
        })
            .then(response => response.json())
            .then((data) => {
                if (data.error) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                alert("Publicitat guardada correctament");
            })
            .catch(error => {
                console.error('Error updating news:', error);
            }
            );




    };



    useEffect(() => {
        getPublicitat();
    }, []);

    const eliminarPublicitat = (id) => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/publicitat/eliminarpublicitatBBDD/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('token'),
            }
        })
            .then(response => response.json())
            .then((data) => {
                if (data.error) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                getPublicitat();
            })

    };



    return (
        <div className="publicitat">
            <div className="mainDashboard">
                <div className="container">
                    <div className='boxDashboad'>
                        <h1 className='tituloDashboard'>Publictat actual a la app</h1>
                        <p style={{ fontSize: "12px" }}>Modifica l'orde de la publicitat</p>
                        {publicitats && (
                            <table className="publicitats-table">
                                <thead>
                                    <tr>
                                        <th>Imagen</th>
                                        <th>Orden</th>
                                        <th>Link</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {publicitats.map((publicitat, index) => (
                                        <tr className="publicitatActual" key={index}>
                                            <td>
                                                <img
                                                    src={`${process.env.REACT_APP_API_BASE_URL}/publicitat/${publicitat.nombre}`}
                                                    alt="publicitat"
                                                    width="100%"
                                                    height="100"
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Orden"
                                                    className="inputNotificar"
                                                    value={publicitat.orden}
                                                    onChange={(e) => {
                                                        const newPublicitat = [...publicitats];
                                                        newPublicitat[index].orden = parseInt(e.target.value);
                                                        setPublicitats(newPublicitat);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <input
                                                    type="text"
                                                    placeholder="Link"
                                                    className="inputLink"
                                                    value={publicitat.url}
                                                    onChange={(e) => {
                                                        const newPublicitat = [...publicitats];
                                                        newPublicitat[index].url = e.target.value;
                                                        setPublicitats(newPublicitat);
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <button className="btnEliminar"
                                                    onClick={() => eliminarPublicitat(publicitat.id)}
                                                >
                                                    Eliminar
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}




                        <button className='btnPubli' onClick={guardarPubli} >Guardar publicitat</button>
                    </div>
                    <div className="PublicacionesJoomla">
                        <div className='boxDashboad'>
                            <h1 className='tituloDashboard'>Pujar publicitat</h1>
                            <p style={{ fontSize: "12px" }}>Dimensions: 2560 per 802 píxels</p>
                            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                            <button className='btnPubli' onClick={updatePubli}>Pujar Publicitat</button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
export default Publicitat;