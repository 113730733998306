import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const AppArticles = ({refresh}) => {

    const [noticies, setNoticies] = useState([]);


useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/noticies?limit=20', {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'mode': 'no-cors',
        },
    })
        .then(response => response.json())
        .then(data => setNoticies(data));
}, [refresh]);


    

    const createData = (id, titol, data, category, tags) => {
        return { id, titol, data, category, tags };
    }

    const rows = noticies.map((noticia) => {
        return createData(noticia.id, noticia.titol, noticia.created, noticia.category, noticia.tags);
    });


    return (
        <div className='boxDashboad'>
            <h1 className='tituloDashboard'>Últimes Publicacions a la APP</h1>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow >
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Titol</TableCell>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Categoria</TableCell>
                            <TableCell align="left">Tags</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">{row.titol}</TableCell>
                                <TableCell align="left">{row.data}</TableCell>
                                <TableCell align="left">{row.category}</TableCell>
                                <TableCell align="left">{row.tags}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
export default AppArticles;