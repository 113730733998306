import { useState } from "react";

const Notificacions = () => {
    const [textEnviar, setTextEnviar] = useState('')
    const [textResultat, setTextResultat] = useState('')

    const notificacions = () => {
 
        fetch(process.env.REACT_APP_API_BASE_URL + '/notifications/enviarnotificacions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('token'),
                'Access-Control-Allow-Origin': '*',
                'mode': 'no-cors',
            },
            body: JSON.stringify({ textEnviar }),
        })
            .then(response => response.json())
            .then((data) => {
                if(data.error){
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                setTextResultat(data.message)
            })
            .catch(error => {
                console.error('Error updating news:', error);
            }
            );
         
            
    };

    
    
    return (
        <div className="mainDashboard">
            <div className="container">
                <div className='boxDashboad'>
                    <h1 className='tituloDashboard'>Notificacions</h1>
                    <p style={{fontSize: "12px"}}>Una vegada enviades les notificacions, poden tardar fins a 30 minuts en arribar als dispositius, per culpa de firebase(sistema de android) i APNs (sistema de IOS). En general sol se inmediat</p>
                    <input type="text" placeholder="Titol" className="inputNotificar2" onChange={(e) => setTextEnviar(e.target.value)} />
                
                    <button className="enviarNotificar" onClick={notificacions}>Enviar</button>
                    <textarea className="resultatNotificacions" placeholder="Resultat" readOnly value={textResultat} />
                </div>
            </div>
        </div>
    );
}
export default Notificacions;