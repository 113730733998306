import { useEffect, useState } from "react";


const Mantinance = () => {
    const [mantinance, setMantinance] = useState(false);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/mantinance', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => setMantinance(data.mantinance))
            .catch((error) => {
                console.error('Error:', error);
            }
            );



    }, []);

    const changeManitinance = (mantinance) => {


        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/modificarmantenimiento', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('token')
            },
            body: JSON.stringify({ mantinance: mantinance })
        })
            .then(response => response.json())
            .then(data => setMantinance(data.mantinance[0].actiu))
            .catch((error) => {
                console.error('Error:', error);
            }
            );


    }

    return (
        <div className="appMantinance">
            <input type="checkbox" checked={mantinance} onChange={(e) => { changeManitinance(e.target.checked) }} />
            <h1>App en manteniment</h1>



        </div>
    );
};
export default Mantinance;