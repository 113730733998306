import AppArticles from "./appArticles";
import JoomlaArticles from "./joomlaArticles";
import { useEffect, useState } from "react";
import Mantinance from "./mantinance";

const Dashboard = () => {
    const [refresh, setRefresh] = useState(false);
    const [updateing, setUpdateing] = useState(false);

    useEffect(() => {
        getMantinance();
        //comprovar el token
        const token = localStorage.getItem('token');
        if (!token) {
            window.location.href = '/login';
        }

        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({ token }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }

            })
            .catch(error => {
                console.error('Error:', error);
            });




        setRefresh(false);
    }, [refresh]);


    const getMantinance = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/auth/mantinance', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('token'),
            }
        })
            .then(response => response.json())
            .then((data) => {
                console.log(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

                


    const update = () => {
        setUpdateing(true);
        fetch(process.env.REACT_APP_API_BASE_URL + '/noticies/update', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Token': localStorage.getItem('token'),
                
            }
        })
            .then(response => response.json())
            .then((data) => {
                if(data.error){
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                }
                
                setUpdateing(false);
                setRefresh(true);
            })
            .catch(error => {
                console.error('Error updating news:', error);
                setUpdateing(false);
            });
    };

 
    return (
        <div className="mainDashboard">
            <div className="mantinance"><Mantinance /></div>
            <div className="container">
                <div className="publicacionesAPP"> <AppArticles refresh={refresh} /></div>
                <div className="PublicacionesJoomla"><JoomlaArticles refresh={refresh} /></div>
                <div className="update"><div className='boxDashboad'>
                    <h1 className='tituloDashboard'>Actualitzar els articles de la APP</h1>
                    <p className='textDashboard'>Afageix els nous articles de JOOMLA a la app</p>
                    {updateing ? <p className='textDashboard'>Actualitzant...</p> : null}

                    <button className='btnUpdate' onClick={update}>Actualitzar</button>
                </div>
                </div>
            
            </div>
        </div>
    );
}
export default Dashboard;