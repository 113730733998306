import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const JoomlaArticles = ({ refresh }) => {

    const [noticies, setNoticies] = useState([]);
    const [lastID, setLastID] = useState(0);


    useEffect(() => {
        ultimIDBBDD();
        getNoticies();
    }, [refresh]);

    const createData = (id, titol, data, category, tags) => {
        return { id, titol, data, category, tags };
    }

    let rows = [];

    if(noticies) {
        rows = noticies.map((noticia) => {
            const tags = noticia.attributes.tags;
            const tagNames = Object.values(tags).join(", ");
            //const tagNames = "d";

            return createData(noticia.attributes.id, noticia.attributes.title, noticia.attributes.created, noticia.relationships.category.data.id, tagNames);
        });
    }

    const ultimIDBBDD = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/noticies/lastid', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
        })
            .then(response => response.json())
            .then(data => setLastID(data.lastId));
    }
    const getNoticies = () => {
        fetch(process.env.REACT_APP_API_BASE_URL + '/noticies/joomla?limit=20', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'mode': 'no-cors',
            },
        })
            .then(response => response.json())
            .then(data => setNoticies(data));
    }

    return (
        <div className='boxDashboad'>
            <h1 className='tituloDashboard'>Últimes Publicacions JOOMLA</h1>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow >
                            <TableCell align="left">ID</TableCell>
                            <TableCell align="left">Titol</TableCell>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Categoria</TableCell>
                            <TableCell align="left">Tags</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row) => (
                            <TableRow
                                className={row.id > lastID ? 'toUpdate' : ''}
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="left">{row.titol.length > 30 ? row.titol.substring(0, 30) + "..." : row.titol}</TableCell>
                                <TableCell align="left">{row.data}</TableCell>
                                <TableCell align="left">{row.category}</TableCell>
                                <TableCell align="left">{row.tags} </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
export default JoomlaArticles;